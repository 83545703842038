import { Injectable } from '@angular/core';
import { PageType } from '@usheru-hff/shared/domain';

@Injectable({
  providedIn: 'root'
})
export class ComingFromService {
  constructor() {}

  private comingFrom: PageType;

  public setComingFrom(comingFrom: PageType) {
    this.comingFrom = comingFrom;
  }

  public getComingFrom() {
    return this.comingFrom;
  }
}
